import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"

import { Container, Row, Col } from "../components/Grid"
import Intro from "../components/Intro"
import Text from "../components/Text"

import Icon from "../images/undraw_web_developer_p3e5.svg"

const SobrePage = () => (
  <Layout>
    <SEO title="Sobre" />

    <Intro
      full={false}
      title="Conheça um pouco da minha história"
      img={{
        src: Icon,
        alt: "",
      }}
    />

    <Container>
      <Row>
        <Col size="12" md="8" offsetMd="2">
          <Text as="h2" color="cloud_light" mt="30" mb="30">
            Oi, eu sou o Bruno!
          </Text>
          <Text color="cloud_light" size="sm" line="15" mb="30">
            Nasci em Curitiba/PR, tenho 29 anos, estou casado desde 2017, sou
            desenvolvedor Front-end no Paraná Banco e atualmente estudo{" "}
            <strong>NodeJS</strong>, <strong>ReactJS</strong> e{" "}
            <strong>React Native</strong> na Rocketseat.
          </Text>
          <Text color="cloud_light" size="sm" line="15" mb="30">
            Desde 2014 atuo como desenvolvedor, inicialmente como Back-End e
            como Full-Stack C#.Net, mas em 2019 redirecionei minha carreira para
            o ecossistema <strong>JavaScript</strong>.
          </Text>
          <Text color="cloud_light" size="sm" line="15" mb="30">
            Sou Técnico em Informática pelo Senai-PR (2014) e Tecnólogo em
            Produção Multimídia pela Faculdade Opet (2017).
          </Text>
          <Text color="cloud_light" size="sm" line="15" mb="100">
            Atualmente estou aprendendo TypeScript e Testes automatizados.
          </Text>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default SobrePage
